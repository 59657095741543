/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")
const { FirebaseProvider } = require("./src/context/FirebaseProvider")
const { AppProvider } = require("./src/context")
const Hotjar = require('@hotjar/browser').default;
const Sentry = require("@sentry/react")
const { BrowserTracing, Replay } = require("@sentry/tracing")

const siteId = 2804378
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

if (process.env.GATSBY_ACTIVE_ENV !== "production") {
  Sentry.init({
    dsn:
      "https://07e0ce9afcf7449e87819631a978e785@o4504615611596800.ingest.sentry.io/4504615618805760",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.GATSBY_ACTIVE_ENV,

    integrations: [new BrowserTracing(), new Sentry.Replay()],
  })
} else {
  Sentry.init({
    dsn:
      "https://07e0ce9afcf7449e87819631a978e785@o4504615611596800.ingest.sentry.io/4504615618805760",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.GATSBY_ACTIVE_ENV,

    integrations: [new BrowserTracing(), new Sentry.Replay()],
  })
}


exports.wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <FirebaseProvider>{element}</FirebaseProvider>
    </AppProvider>
  )
}

/* exports.onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
} */
