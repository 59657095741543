import React, { useReducer, createContext } from "react"
import { MenuTypeEnum, OffCanvasTypeEnum } from "./app.types"
import { GlobalAppTypes, GlobalAppActionMap } from "./actions"
import { toggleItemInEnumArray } from "utils/array"
import { ModalPage } from "app/Dialog/dialog.types"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialAppStateType = {
  toggledMenus: MenuTypeEnum[]
  toggledOffCanvas: OffCanvasTypeEnum | null
  sidebarToggled: boolean
  settingsToggled: boolean
  navToggled: boolean
  currentTime: number
  modalPage: ModalPage | null
}

const initialState: InitialAppStateType = {
  toggledMenus: [],
  toggledOffCanvas: null,
  navToggled: false,
  sidebarToggled: false,
  settingsToggled: false,
  currentTime: +new Date(),
  modalPage: null,
}

const AppContext = createContext<{
  state: InitialAppStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const mainReducer = (
  state: InitialAppStateType,
  action: GlobalAppActionMap
) => {
  switch (action.type) {
    case GlobalAppTypes.ToggleMenu:
      return {
        ...state,
        toggledMenus: toggleItemInEnumArray<MenuTypeEnum>(
          state.toggledMenus,
          action.payload.key
        ),
      }
    case GlobalAppTypes.ToggleOffCanvas:
      return {
        ...state,
        toggledOffCanvas: action.payload.key,
      }
    case GlobalAppTypes.ToggleNav:
      return {
        ...state,
        navToggled: action.payload.toggled ?? !state.navToggled,
      }
    case GlobalAppTypes.ToggleSettings:
      return {
        ...state,
        settingsToggled: action.payload.toggled
          ? action.payload.toggled
          : !state.settingsToggled,
      }
    case GlobalAppTypes.ToggleSidebar:
      return {
        ...state,
        sidebarToggled: action.payload.toggled,
      }
    case GlobalAppTypes.SetCurrentModal:
      return {
        ...state,
        modalPage: action.payload.modal,
      }
    case GlobalAppTypes.SetCurrentTime:
      return {
        ...state,
        currentTime: +new Date(),
      }
    case GlobalAppTypes.Reset:
      return initialState
    default:
      return state
  }
}

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
